<template>
  <div></div>
</template>

<script setup>
import { onMounted } from 'vue'

import useWindowCacheBust from '@/hooks/useWindowCacheBust'
import useWindowCacheRefresh from '@/hooks/useWindowCacheRefresh'

const refresh = useWindowCacheRefresh()
onMounted(() => useWindowCacheBust(refresh))
</script>
